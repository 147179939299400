import { FC } from "react";

interface ThankYouContentProps {
    setOpenRules: () => void;
    setOpen: () => void;
    userName: string;
    kidName: string;
    kidAge: string;
}

const ThankYouContent: FC<ThankYouContentProps> = ({
    setOpenRules,
    setOpen,
    userName,
    kidName,
    kidAge,
}) => {
  return (
    <div className="w-[990px] h-[466px] pl-[207px] pr-[207px] bg-white rounded-2xl flex justify-center items-center">
        <div>
            <h1 className='text-[26px] font-medium'>{userName.split(" ")[0]}, спасибо за твою отзывчивость и участие в проекте "Твори добро"! 🤗❤️🎁</h1>
            <br/>
            <span className="text-[22px] flex pb-[31px]"><p className="font-medium">Твой подарок получит</p><pre> </pre>{kidName}, {kidAge} лет</span>
            <p className="font-medium text-[22px]">Что делать дальше?</p>
            <span className="flex text-[22px]"><div>Все дальнейшие шаги описаны в <a onClick={() => {
                setOpen();
                setOpenRules();
            }} href="#1" className="text-[#D90E2E] underline cursor-pointer">этой инструкции.</a> Прочти, пожалуйста</div></span>
            <br/>
            <p className="text-[22px]">Мы также продублировали правила тебе в телеграм.</p>
        </div>
    </div>
  )
}

export default ThankYouContent
