import React, { FC } from 'react'

interface RulesContentProps {
    setOpen: () => void;
}

const RulesContent: FC<RulesContentProps> = ({
    setOpen,
}) => {
    setTimeout(() => {
        const rulesDialog = document.getElementById("dialog");
        rulesDialog?.scroll(0,0);
    })

  return (
    <div id="rules-modal">
        <div className="absolute left-[50%] ml-[-41px] top-[-20px]">
            <img src="./static/moroz.png" alt="Дед Мороз" />
        </div>
        <div className="w-[990px] h-[1352px] bg-white pt-[73px] pl-[206px] pr-[206px]">
            <div className="flex flex-col items-center">
                <p className="main-title text-[36px] font-medium font-bold">
                    Как стать
                </p>
                <p className="main-title text-[36px] font-medium font-bold">
                    Дедом Морозом?
                </p>
            </div>
            <div className="pt-[44px]">
                <p className="text-[18px] pb-[44px]">
                    🌟1. На сайте собраны письма Деду Морозу от детей разного возраста, ты можешь зайти в разные карточки и ознакомиться с пожеланиями ребят.
                </p>
                <span className="text-[18px] pb-[44px]">
                    <p>🌟2. Если ты остановил свой выбор на каком-то письме, необходимо нажать кнопку «Сделать подарок»</p>
                    <p className="text-[#D90E2E] pt-[22px]"><b>Важно:</b> ты можешь сделать выбор только 1 раз, после нажатия кнопки за тобой закрепляется подарок (отменить его нельзя) и его больше никто не сможет выбрать. В карточке будет отображаться твое имя. </p>
                </span>
                <p className="text-[18px] pb-[44px] pt-[44px]">
                    🌟3. Можно объединяться с коллегами для покупки подарка. Пиши нам, если нужна помощь в поиске пары. Но! Подарок может быть закреплен только за 1 человеком.
                </p>
                <p className="text-[18px] pb-[44px]">
                    🌟4. В некоторых карточках указаны подарки с ссылками на товары, где-то просто вещь. Важно, чтобы подарок соответствовал пожеланию. Например, если человек просит кроссовки, ему не нужно покупать что-то другое.
                </p>
                <p className="text-[18px] pb-[44px]">
                    🌟5. Если вы находитесь в другом городе просим вас отправить подарок в нижегородский офис как можно раньше (до 10 декабря), чтобы он точно был у нас к моменту завершения акции 15 декабря.
                </p>
                <p className="text-[18px] pb-[44px]">
                    🌟6. Подарки поедут к детям на неделе с 17 по 22 декабря в детский дом <a className="text-[#D90E2E]" href="https://balachna-detdom-nigres.edusite.ru/" rel="noreferrer" target="_blank">НиГРЭС в Балахне</a>.
                </p>
                <p className="text-[18px] pb-[44px]">
                    По всем вопросам можно обращаться к <a className="text-[#D90E2E]" href="https://t.me/katerinashepetova" rel="noreferrer" target='_blank'>Кате</a> и <a className="text-[#D90E2E]" rel="noreferrer" target="_blank" href="https://t.me/yana_shigontseva">Яне</a>
                </p>
                <div className="flex flex-col items-center">
                    <button
                        onClick={setOpen}
                        type="button"
                        className="mt-8 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out bg-[#00CA89] w-[199px] h-[56px] text-[16px]">
                        ЯСНО-ПОНЯТНО 👌
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RulesContent;

