import axios from "axios";
import { Letter, UserInfo } from "../types";
import { config } from "../config";

export const getInfo = () => {
    return axios.get<UserInfo>("https://dobro.morizo.ru/api/me", { headers: config });
}

export const getLetters = () => {
    return axios.get<Letter[]>("https://dobro.morizo.ru/api/letters", { headers: config });
}

export const getLetterById = (id: string) => {
    return axios.get<Letter>(`https://dobro.morizo.ru/api/letters/${id}`, { headers: config });
}

export const selectLetter = (id: string) => {
    return axios.post(`https://dobro.morizo.ru/api/letters/${id}`, { result: true }, { headers: config });
}

