import { FC } from "react";

interface ImageContentProps {
    setOpenImage: () => void;
    src: string[];
}

const ImageContent: FC<ImageContentProps> = ({
    setOpenImage,
    src
}) => {
  return (
    <div className="w-[990px] rounded-2xl flex justify-center items-center">
        <div>
            {
                src.map((item, key) => {
                    return <img key={`img-${key}`} className="mt-[20px]" width="100%" height="100%" src={item} alt="Изображение письма" />
                })
            }
        </div>
    </div>
  )
}

export default ImageContent