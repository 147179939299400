const MainTitle = () => {
  return (
    <div className="flex justify-center main-title">
        <div className="mt-20">
            <img className="absolute left-[48%] top-[2%]" src="./static/ball.png" alt="ball" />
            <img className="absolute left-[56%] top-[3.5%]" src="./static/blick.png" alt="blick" />
            <img className="absolute left-[28%] top-[5%]" src="./static/bird.png" alt="bird" />
            <img className="absolute left-[31%] top-[30%]" src="./static/stick.png" alt="stick" />
            <img className="absolute right-[28%] top-[25%]" src="./static/snowflake.png" alt="snowflake" />
            <p className="font-medium text-4xl font-bold tracking-tight text-white sm:text-9xl">ТВОРИ</p>
            <p className="font-medium text-4xl text-[#00CA89] font-bold tracking-tight text-white sm:text-9xl">ДОБРО</p>
        </div>
    </div>
  )
}

export default MainTitle;
