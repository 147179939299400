import { FC } from "react";

interface GiftProps {
    setOpen: () => void;
}

const Gift: FC<GiftProps> = ({
    setOpen,
}) => {
  return (
    <>
        <div className="w-[889px] h-[295px] flex m-auto mt-24">
            <div>
                <img src="./static/gift-group.png" alt="gift-group"/>
            </div>
            <div className="flex flex-col justify-center">
                <p className="text-[24px] text-white leading-1">
                    <pre>Открой карточку и выбери одно письмо</pre>
                    <pre>с новогодним подарком для ребенка.</pre>
                </p>
                <div className="flex">
                    <a href="#cards">
                        <button
                            type="button"
                            className="mr-[8px] mt-8 inline-block rounded-full px-6 pb-2 pt-2.5 font-medium uppercase leading-normal transition duration-150 ease-in-out bg-[#00CA89] h-[56px] text-[16px]">
                            Сделать подарок ↓
                        </button>
                    </a>
                    <button
                        onClick={setOpen}
                        type="button"
                        className="ml-[8px] mt-8 rules-button inline-block rounded-full px-6 pb-2 pt-2.5 text-[#909098] font-medium uppercase leading-normal transition duration-150 ease-in-out border-slate-200 h-[56px] text-[16px]">
                        Правила участия
                    </button>
                </div>
            </div>
        </div>
    </>
  )
}

export default Gift