import MainCard from "./MainCard"

const options = [
    {
        num: "1",
        title: "Авторизуйтесь через корпоративный ID",
        content: <button
            className="mt-8 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out bg-white md:w-[120px] lg:w-[160px] xl:w-[310px] h-[56px] md:w-[14px] lg:text-[10px] xl:text-[16px]"
            onClick={() => window.location.href="https://dobro.morizo.ru/api/auth"}
        >Войти через MORIZO.ID</button>
    },
    {
        num: "2",
        title: "Прочтите письма с пожеланиями детей",
        content: null
    },
    {
        num: "3",
        title: "Выберите ребенка, которому вы хотите подарить подарок",
        content: null
    }
]

const MainCards = () => {
  return (
    <div className="flex justify-center mt-44" id="main-cards">
        {
            options.map((item, key) => {
                return <MainCard key={`main-card-${key}`} num={item.num} title={item.title} content={item.content}/>
            })
        }
    </div>
  )
}

export default MainCards