import { useEffect, useRef, useState } from "react";
import AdditionalBackground from "./components/AdditionalBackground";
import MainCards from "./components/cards/MainCards";
import Gift from "./components/gift-block/Gift";
import KidsCards from "./components/kids-cards/KidsCards";
import MainDescription from "./components/MainDescription";
import MainFooter from "./components/MainFooterText";
import MainTitle from "./components/MainTitle";
import { UserInfo } from "./types";
import { getInfo } from "./api";
import Modal from "./components/modal/Modal";
import RulesContent from "./components/modal/RulesContent";

function App() {

  const [openRules, setOpenRules] = useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  const handleOpenRules = () => {
    setOpenRules(!openRules);
  }

  const params = new URLSearchParams(window.location.search)
  const token = params.get("token");
  token && localStorage.setItem("token", token);

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();

  useEffect(() => {
    localStorage.getItem("token") && getInfo().then((response) => {
      setUserInfo(response.data);
      params.delete("token");
    });
  }, [token]);

  if (token) {
    window.location.href = "/";
  }

  return (
    <div className="App">
      <AdditionalBackground />
      <MainTitle />
      {
        localStorage.getItem("token") ? (
          <>
          <Modal 
            cancelButtonRef={cancelButtonRef}
            open={openRules}
            setOpen={setOpenRules}
            children={<RulesContent 
                setOpen={handleOpenRules}
            />}
        />
            <Gift setOpen={handleOpenRules}/>
            <KidsCards user={userInfo}/>
          </>
        ) : (
          <>
            <MainDescription />
            <MainCards />
          </>
        )
      }
      <MainFooter />
    </div>
  );
}

export default App;
