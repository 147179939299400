const MainFooter = () => {
  return (
    <>
        <div className="flex justify-center main-title text-center mt-44">
            <div className="leading-1">
                <p className="text-white text-[100px] leading-tight">ДАВАЙТЕ ТВОРИТЬ</p>
                <p className="text-white text-[100px] leading-tight">ДОБРО ВМЕСТЕ</p>
            </div>
        </div>
        <div className="flex justify-center mt-[97px] mb-[159px]">
            <img src="./static/footer-ball.png" alt="footer-ball" />
        </div>
    </>
  )
}

export default MainFooter
