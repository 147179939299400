const MainDescription = () => {
  return (
    <div className='flex m-auto w-[789px] mt-8'>
        <div className="flex flex-col items-center">
            <p className="text-white text-[23.18px] text-center vela-sans">
                Делайте добро и оно вернется к вам. Поддержите наш благотворительный проект и создайте цепь добра, которая простирается через все поколения.
            </p>
            <a href="#main-cards">
              <button
                  type="button"
                  className="mt-8 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out bg-[#00CA89] w-[293px] h-[56px] text-[16px]">
                  Принять участие в акции
              </button>
            </a>
        </div>
    </div>
  )
}

export default MainDescription
