import { FC } from "react";
import Hexagon from "./Hexagon"

interface CardProps {
  num: string;
  title: string;
  content: JSX.Element | null;
}

const MainCard: FC<CardProps> = ({
  num,
  title,
  content,
}) => {
  return (
    <div className="flex flex-row w-[365.36px] h-[365.36px] main-card rounded-3xl m-[47.95px] overflow-hidden">
      <Hexagon num={num}/>
      <div className={`${!content ? "mt-[205px]" : "mt-[173px]"} pl-[28px] pr-[28px]`}>
        <p className="main-card-hexagon-text lg:text-[12px] xl:text-[24.4px] text-white">{title}</p>
        {content}
      </div>
    </div>
  )
}

export default MainCard